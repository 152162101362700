<script setup lang="ts">
import { useI18n } from 'vue-i18n'
useI18n()
</script>

<template>
  <footer class="top">
    <div class="footer frame cl-white u-row">
      <img class="logo" :src="'/images/logo-white.png'" alt="Open Room Inc." />
      <div class="right u-col">
        <nav class="links u-row">
          <a href="https://openrm.co.jp/policy.html" v-t="'privacyPolicy'" />
          <a href="https://openrm.co.jp" v-t="'terms'" />
          <a href="https://openrm.co.jp" v-t="'company'" />
        </nav>
        <p v-t="'copyright'" />
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  margin-top: auto;
  width: 100%;
  background-color: var(--cl-black-90);
}
.footer {
  height: 100px;
  margin: 0 auto;
}
.footer .logo {
  height: 48px;
  width: 48px;
  margin-right: 48px;
}
.footer .right {
  align-items: start;
}
.footer .right p {
  margin-top: 12px;
}
.footer .links a:not(:first-child) {
  margin-left: 48px;
}
@media (max-width: 1060px) {
  footer {
    padding: 24px 0;
  }
  .footer {
    height: unset;
    margin: 0;
  }
  .footer .logo {
    margin-bottom: 32px;
  }
  .footer,
  .footer .links {
    flex-direction: column;
    align-items: start;
  }
  .footer,
  .footer .links {
    flex-shrink: 0;
  }
  .footer .links a:not(:first-child) {
    margin: 12px 0 0;
  }
}
</style>

<i18n lang="yaml">
en:
  privacyPolicy: 'Privacy Policy'
  terms: 'Terms of Use'
  company: 'Company'
  copyright: '© 2017-2023 Open Room Inc.'
ja:
  privacyPolicy: '個人情報の取り扱い'
  terms: '利用規約'
  company: '運営会社'
  copyright: '© 2017-2023 Open Room Inc.'
</i18n>
